// /*!

 // =========================================================
 // * Material Kit Pro - v2.1.1
 // =========================================================
 //
 // * Product Page: https://www.creative-tim.com/product/material-kit-pro
 // * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 //
 // =========================================================
 //
 // * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 //
 // */

@import 'partials/kit/core/variables';
@import 'partials/kit/core/mixins';
@import 'partials/kit/core/core-bootstrap';
@import 'partials/kit/core/reboot';

// Core Components
@import 'partials/kit/core/layout';
@import 'partials/kit/core/buttons';
@import 'partials/kit/core/misc';
@import 'partials/kit/pro/misc-extend';
@import 'partials/kit/core/checkboxes';
@import 'partials/kit/core/custom-forms';
@import 'partials/kit/core/switches';
@import 'partials/kit/core/radios';
@import 'partials/kit/core/carousel';
@import 'partials/kit/core/forms';
@import 'partials/kit/core/input-group';
@import 'partials/kit/core/list-group';
@import 'partials/kit/core/nav';
@import 'partials/kit/core/headers';
@import 'partials/kit/core/footers';
@import 'partials/kit/core/images';
@import 'partials/kit/core/navbar';
@import 'partials/kit/core/badges';
@import 'partials/kit/core/alerts';
@import 'partials/kit/core/pagination';
@import 'partials/kit/core/pills';
@import 'partials/kit/core/info-areas';
@import 'partials/kit/core/type';
@import 'partials/kit/core/tabs';
@import 'partials/kit/core/tooltip';
@import 'partials/kit/core/popover';
@import 'partials/kit/core/modal';
@import 'partials/kit/pro/modal-extend';
@import 'partials/kit/core/dropdown';
@import 'partials/kit/core/drawer';
@import 'partials/kit/core/progress';
@import 'partials/kit/core/togglebutton';
@import 'partials/kit/core/ripples';
@import 'partials/kit/pro/social-buttons';
@import 'partials/kit/pro/fileupload';
@import 'partials/kit/pro/tables';
@import 'partials/kit/pro/sections';
@import 'partials/kit/pro/media';

// Cards
@import 'partials/kit/core/cards';
@import 'partials/kit/core/cards/card-carousel';
@import 'partials/kit/core/cards/card-login';
@import 'partials/kit/core/cards/card-plain';
@import 'partials/kit/pro/cards/card-background';
@import 'partials/kit/pro/cards/card-blog';
@import 'partials/kit/pro/cards/card-collapse';
@import 'partials/kit/pro/cards/card-contact';
@import 'partials/kit/pro/cards/card-form-horizontal';
@import 'partials/kit/pro/cards/card-pricing';
@import 'partials/kit/pro/cards/card-product';
@import 'partials/kit/pro/cards/card-profile';
@import 'partials/kit/pro/cards/card-rotate';
@import 'partials/kit/pro/cards/card-testimonials';

//Plugins
@import 'partials/kit/core/plugins/plugin-datetime-picker';
@import 'partials/kit/core/plugins/plugin-nouislider';
@import 'partials/kit/pro/plugins/plugin-bootstrap-select';
@import 'partials/kit/pro/plugins/plugin-flexisel';
@import 'partials/kit/pro/plugins/plugin-tagsinput';

// Sections and Example pages for PRO

@import 'partials/kit/pro/example-pages-extend';
@import 'partials/kit/pro/sections/headers-extend';
@import 'partials/kit/pro/sections/footers-extend';
@import 'partials/kit/pro/sections/social-subscribe-lines';
@import 'partials/kit/pro/sections/features';
@import 'partials/kit/pro/sections/team';
@import 'partials/kit/pro/sections/pricing';
@import 'partials/kit/pro/sections/blogs';
@import 'partials/kit/pro/sections/projects';
@import 'partials/kit/pro/sections/testimonials';
@import 'partials/kit/pro/sections/contactus';

@import 'partials/kit/core/responsive';
